<template>
  <div class="detail h-backgroud-color_body">
    <van-tabs sticky class="tab-cls">
      <van-tab title="基本信息">
        <div v-if="PageScript" class="h-p-5">
          <template v-for="formItem in pageLayout">
            <div v-if="formItem.fmCode !== 'fileRecords'" :key="formItem.fmCode" class="form h-shadow-sm">
              <div class="form-title">
                <span class="title">{{ formItem.fmName }}</span>
              </div>
              <div class="form-content">
                <HYFormView :ref="formItem.fmCode + 'FormRef'" :config="formItem" :form="pageForm[formItem.fmCode]"> </HYFormView>
              </div>
            </div>
          </template>
        </div>
      </van-tab>
      <van-tab title="影像信息">
        <div v-if="fileLayout" class="h-p-5">
          <template v-for="imgItem in fileLayout">
            <div :key="imgItem.prop" class="form h-shadow-sm">
              <div class="h-flex h-flex-wrap h-col-center">
                <div class="form-title h-flex h-flex-wrap h-row-between" :class="{ 'form-title--required': imgItem.required }">
                  <span class="title">{{ imgItem.label }}</span>
                  <div v-if="imgItem.tip">
                    <van-popover v-model="imgItem.tipPopover" placement="top" trigger="click">
                      <div class="h-font-sm h-text-secondary-color h-p-4">{{ imgItem.tip }}</div>
                      <template #reference>
                        <van-icon name="info" size="18" color="#57ccc2" />
                      </template>
                    </van-popover>
                  </div>
                </div>
              </div>
              <div class="h-p-10">
                <van-uploader v-model="pageForm.fileRecords[imgItem.prop]" :multiple="imgItem.multiple" :max-count="imgItem.limitNumber" :readonly="true" :deletable="false" :show-upload="false">
                  <template #preview-cover="item">
                    <div v-if="isPdf(item)">
                      <PdfPopupView :title="item.fileName" :pdfUrl="item.filePath">
                        <van-icon name="/images/upload/pdf.png" size="100%" />
                      </PdfPopupView>
                    </div>
                  </template>
                </van-uploader>
              </div>
            </div>
          </template>
        </div>
      </van-tab>
      <van-tab title="审核进度">
        <div v-if="failReason" class="form h-shadow-sm">
          <div class="form-title h-flex h-flex-wrap h-row-between">
            <span class="title">预约失败</span>
          </div>
          <div class="form-content h-p-10 h-font-xl">{{ failReason }}</div>
        </div>
        <div v-if="auditList && auditList.length > 0" class="form h-shadow-sm">
          <div class="form-title h-flex h-flex-wrap h-row-between">
            <span class="title">审核进度</span>
          </div>
          <div class="form-content">
            <van-steps direction="vertical" :active="auditList.length - 1" active-color="#57ccc2" inactive-icon="checked">
              <template v-for="(item, index) in auditList">
                <van-step :key="index">
                  <div>{{ getTimeStamp(item, index) }}</div>
                  <div class="h-text-desc-color">
                    <template v-if="index === 0">
                      <div class="h-m-v-8">
                        <label>申请人：</label>
                        {{ item.checkerName }}
                      </div>
                    </template>
                    <template v-else>
                      <div class="h-m-v-8">
                        <label>{{ getCheckerName(item) }}：</label>
                        {{ item.checkerName }}
                      </div>
                      <div v-if="item.nodeCode !== 'First'" class="h-m-v-8">
                        <label>审核结果：</label>
                        {{ item.checkResult }}
                      </div>
                      <div v-if="item.nodeCode !== 'First'" class="h-m-v-8">
                        <label>审核意见：</label>
                        <div style="line-height: 16px;display: inline-block;">{{ item.checkSuggest }}</div>
                      </div>
                    </template>
                  </div>
                </van-step>
              </template>
            </van-steps>
          </div>
        </div>
        <div v-else>
          <div class="none-tip">
            <van-image :src="require('@/assets/images/none_data.jpg')" />
            <div class="h-font-lg h-text-secondary-color">暂无审核记录！</div>
          </div>
        </div>
      </van-tab>
      <van-tab v-if="taskObj.taskList && taskObj.taskList.length > 0" title="派车进度">
        <div class="form h-shadow-sm">
          <div class="form-title h-flex h-flex-wrap h-row-between">
            <span class="title">接车人信息</span>
          </div>
          <div class="form-content">
            <HYFormView fmCode="receiver" :config="taskObjLayout.receiver" :form="taskObj.receiver"> </HYFormView>
          </div>
        </div>
        <div class="form h-shadow-sm">
          <div class="form-title h-flex h-flex-wrap h-row-between">
            <span class="title">约还车信息</span>
          </div>
          <div class="form-content">
            <HYFormView fmCode="delivery" :config="taskObjLayout.delivery" :form="taskObj.delivery"> </HYFormView>
          </div>
        </div>
        <div v-if="taskObj.taskList && taskObj.taskList.length > 0" class="form h-shadow-sm">
          <div class="form-title h-flex h-flex-wrap h-row-between">
            <span class="title">派车进度</span>
          </div>
          <div class="form-content">
            <van-steps direction="vertical" :active="taskObj.taskList.length - 1" active-color="#57ccc2" inactive-icon="checked">
              <template v-for="(item, index) in taskObj.taskList">
                <van-step :key="index">
                  <div>{{ item.strName }} {{ dayjs(item.createTime).format("YYYY-MM-DD HH:mm") }}</div>
                </van-step>
              </template>
            </van-steps>
          </div>
        </div>
        <div v-else>
          <div class="none-tip">
            <van-image :src="require('@/assets/images/none_data.jpg')" />
            <div class="h-font-lg h-text-secondary-color">暂无派车记录！</div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import HYFormView from "@/components/FormView.vue";
import { mapState } from "vuex";
import { request } from "@/api/service";
import dayjs from "dayjs";
import layoutData from "@/views/product/layout/claimData";
import scriptLib from "@/views/product/layout/claimScript";
import PdfPopupView from "@/components/Pdf/pdfPopup";
export default {
  name: "PorductClaimDetail",
  components: { HYFormView, PdfPopupView },
  provide() {
    return {
      pageContext: this,
      pageForm: this.pageForm,
      pageLayout: this.pageLayout,
      pageLayoutMap: this.pageLayoutMap,
      PageScript: this.PageScript
    };
  },
  data() {
    return {
      productCode: null,
      id: null,
      pageForm: {
        fileRecords: {}
      },
      pageLayout: [],
      pageLayoutMap: {},
      PageScript: scriptLib.call(this),
      fileLayout: [],
      auditList: [],
      failReason: null,
      taskObj: {
        receiver: {},
        delivery: {},
        taskList: []
      },
      taskObjLayout: {
        receiver: {
          fmCode: "receiver",
          fmName: "接车人信息",
          labelWidth: "120px",
          formItems: [
            {
              prop: "receiverName",
              label: "接车人",
              view: true
            },
            {
              prop: "receiverCertType",
              label: "接车人证件类型",
              view: true,
              filters: "dictFormat('CS_RE_CERT_TYPE')"
            },
            {
              prop: "receiverNo",
              label: "接车人证件号",
              view: true
            },
            {
              prop: "receiverMobile",
              label: "接车人手机号",
              view: true
            }
          ]
        },
        delivery: {
          fmCode: "delivery",
          fmName: "约还车信息",
          labelWidth: "100px",
          formItems: [
            {
              prop: "startDate",
              label: "预约取车时间",
              filters: "dateFormat('YYYY-MM-DD HH:mm')",
              view: true
            },
            {
              prop: "endDate",
              label: "预约还车时间",
              filters: "dateFormat('YYYY-MM-DD HH:mm')",
              view: true
            },
            {
              prop: "receiverAddress",
              label: "接车地点",
              view: true
            },
            {
              prop: "brandName",
              label: "品牌",
              view: true
            },
            {
              prop: "vehicleModel",
              label: "车型名称",
              view: true
            },
            {
              prop: "plateNo",
              label: "车牌号",
              view: true
            },
            {
              prop: "seatCount",
              label: "最大乘坐人数",
              view: true
            },
            {
              prop: "vehicleTag",
              label: "车型级别",
              view: true
            },
            {
              prop: "gearName",
              label: "拍档类型",
              view: true
            },
            {
              prop: "fuelType",
              label: "燃油类型",
              view: true
            }
          ]
        }
      }
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    scriptObj() {
      const pageScriptObj = new this.PageScript();
      pageScriptObj.pageForm = this.pageForm;
      pageScriptObj.pageLayout = this.pageLayout;
      pageScriptObj.pageLayoutMap = this.pageLayoutMap;
      pageScriptObj.pageContext = this;
      return pageScriptObj;
    }
  },
  async created() {
    const { id, productCode } = this.$route.query;
    this.id = id;
    this.productCode = productCode;
    await layoutData.call(this);
    this.init();
  },
  methods: {
    dayjs,
    init() {
      this.scriptObj.serviceToPage(this.id);
      this.getApplyData();
      this.initFileLayout();
    },
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    initFileLayout() {
      if (!(this.pageLayout && this.pageLayout.length > 0)) {
        return false;
      }
      const filePageLayout = this.pageLayout.filter(res => res.fmCode === "fileRecords");
      if (!(filePageLayout && filePageLayout.length > 0)) {
        return false;
      }
      filePageLayout.forEach(item => {
        if (!item.formItems) {
          return false;
        }
        this.fileLayout = this.fileLayout.concat(item.formItems);
      });
    },
    isPdf(fileItem) {
      if (fileItem.fileType === "application/pdf") {
        return true;
      }
      if (!fileItem.fileName) {
        return false;
      }
      const suffix = fileItem.fileName.substring(fileItem.fileName.lastIndexOf(".") + 1);
      if (".pdf".indexOf(suffix) !== -1) {
        return true;
      }
      return false;
    },
    getApplyData() {
      if (!this.id) {
        return false;
      }
      request({
        url: "/afis-carservice/web/claim/info",
        method: "post",
        data: { id: this.id }
      })
        .then(res => {
          this.getAuditList(res.wfInstanceId);
          if (res.claimType === "V" && res.rentalId) {
            this.getDeliveryTask(res.rentalId);
          }
          this.failReason = res.failReason;
        })
        .catch(error => {});
    },
    getAuditList(wfInstanceId) {
      if (!wfInstanceId) {
        return false;
      }
      request({
        url: `/afis-carservice/workflow/task/history/instance/${wfInstanceId}`,
        method: "get"
      })
        .then(res => {
          this.auditList = res.reverse();
        })
        .catch(error => {});
    },
    getDeliveryTask(id) {
      if (!id) {
        return false;
      }
      request({
        url: `/afis-rental/order/rentalOrder/info/${id}`,
        method: "get"
      })
        .then(res => {
          this.taskObj.receiver = {
            receiverName: res.delivery.receiverName,
            receiverCertType: res.delivery.receiverCertType,
            receiverNo: res.delivery.receiverNo,
            receiverMobile: res.delivery.receiverMobile
          };
          let vehicleTag = "";
          res.vehicleLevel &&
            this.dictMap["CS_DB_CAR_TYPE"] &&
            this.dictMap["CS_DB_CAR_TYPE"].map(d => {
              if (d.value === res.vehicleLevel) {
                vehicleTag = d.label;
              }
            });
          this.taskObj.delivery = {
            startDate: res.startDate,
            endDate: res.endDate,
            receiverAddress: res.delivery.receiverAddress,
            brandName: res.delivery.brandName,
            vehicleModel: res.delivery.vehicleModel,
            plateNo: res.plateNo,
            seatCount: res.delivery.seatCount,
            vehicleTag,
            gearName: res.delivery.gearName,
            fuelType: res.delivery.fuelType
          };
          if (res.trails) {
            this.taskObj.taskList = res.trails;
          }
        })
        .catch(error => {});
    },
    getTimeStamp(item, index) {
      const nodeCode = item.nodeCode;
      const checkerTimeText = nodeCode === "First" ? "提交时间" : "审核时间";
      return `${index === 0 ? "申请时间" : checkerTimeText}：${dayjs(item.checkerTime).format("YYYY-MM-DD HH:mm")}`;
    },
    getCheckerName(item) {
      const nodeCode = item.nodeCode;
      let checkerName = item.nodeName;
      switch (nodeCode) {
        case "First":
          if (item.checkSuggest === "主动撤回") {
            checkerName = "主动撤回";
          } else {
            checkerName = "重新提交";
          }
          break;
      }
      return checkerName;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.detail {
  height: 100vh;
}
.form {
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: $white;
  position: relative;
  box-shadow: 0 0 10upx rgba(0, 0, 0, 0.1);
  margin: 10px 5px;
  .form-title {
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    line-height: $line-height-lg;
    padding: $padding-xs;
    .title {
      border-left: 5px solid $primary-color;
      padding: 0 8px;
    }
  }
}
.row {
  padding: 12px 5px;
  border-bottom: 1px solid #ebedf0;
}
.btn {
  position: fixed;
  bottom: 0;
  background: #fff;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  left: 0;
  right: 0;
}
.tab-cls {
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
}
.back {
  height: 44px;
  background-color: $white;
}
.bottom-cls {
  height: 44px;
}
.none-tip {
  text-align: center;
  margin: 10px;
  padding-bottom: 20px;
  border-radius: 5px;
  background-color: $white;
  ::v-deep .van-image {
    margin-top: 20px;
  }
}
::v-deep .van-uploader__preview-cover {
  background: #f7f8fa;
  padding-top: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
